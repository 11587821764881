export const state = {
  brandnamicContacts: [],
  brandnamicContact: null,
  brandnamicPersonalitiesMap: {},
  brandnamicSelectedPersonalities: {},
  brandnamicActivePersonality: null,
  uiFlags: {
    isFetching: false,
  },
};

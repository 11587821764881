/* global axios */
import { stagingUrl } from '../api/automation.stub';
import JwtAuth from './jwtAuth';

const url =
  process.env.FRONTEND_ENV === 'develop'
    ? stagingUrl + '/deepl' /* process.env.DEEPL_BACKEND_URL */
    : window.location.protocol + '//' + window.location.hostname + '/deepl';

export const buildMessageTemplatesParams = (page, sortAttr, limit, search) => {
  if (!page && !sortAttr && !limit) {
    return '';
  }

  let query = '?';

  if (search) {
    query += `q=${search}&`;
  }

  if (page) {
    query += `page=${page}&`;
  }

  if (sortAttr) {
    query += `sort=${sortAttr}&`;
  }

  if (limit) {
    query += `limit=${limit}`;
  }

  if (query[query.length - 1] === '&') {
    query = query.slice(0, query.length - 1);
  }

  return query;
};

let isLanguagesFetching = false;

class DeeplAPI {
  getCountriesList = async (targetLanguage = 'en-US') => {
    if (isLanguagesFetching) {
      return Promise.resolve({});
    }

    const jwtAuthTokens = await JwtAuth.getUserTokens();

    isLanguagesFetching = true;

    const response = await axios.get(
      `${url}/get-countries-list?lang=${targetLanguage}`,
      {
        headers: {
          Authorization: `Bearer ${jwtAuthTokens.access_token}`,
        },
      }
    );

    isLanguagesFetching = false;

    return response;
  };

  translateText = async (text, targetLanguage, tagHandling = undefined) => {
    if (isLanguagesFetching) {
      return Promise.resolve({});
    }

    const jwtAuthTokens = await JwtAuth.getUserTokens();

    const body = { text, targetLanguage };

    if (tagHandling) {
      body.tagHandling = tagHandling;
    }

    return axios.post(`${url}/translate-text`, body, {
      headers: {
        Authorization: `Bearer ${jwtAuthTokens.access_token}`,
      },
    });
  };
}

export default new DeeplAPI();

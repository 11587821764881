// Libs //
import { mapGetters } from 'vuex';

// Constants //
import { INBOX_TYPES } from '../../shared/mixins/inboxMixin';

export default {
  computed: {
    ...mapGetters({
      inboxes: 'inboxes/getInboxes',
    }),

    hasWhatsappInbox() {
      return this.inboxes?.some(
        (inbox) =>
          inbox?.additional_attributes?.apiInboxType === 'WhatsApp' ||
          inbox.channel_type === INBOX_TYPES.WHATSAPP
      );
    },
  },
};

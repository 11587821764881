// Process *this*, _this_ and ~that~

// 0x5f: _
// 0x2a: *
// 0x7e: ~

// Insert each marker as a separate text token, and add it to delimiter list
//

const SYMBOLS = [0x5f, 0x2a, 0x7e];

const SYMBOLS_TAGS = {
  0x5f: 'em',
  0x2a: 'strong',
  0x7e: 's',
};

function emphasis_tokenize(state, silent) {
  const start = state.pos;
  const marker = state.src.charCodeAt(start);

  if (silent) {
    return false;
  }

  if (!SYMBOLS.includes(marker)) {
    return false;
  }

  const scanned = state.scanDelims(state.pos /* marker === 0x2a */);

  if (scanned.length !== 1) {
    return false;
  }

  for (let i = 0; i < scanned.length; i += 1) {
    const token = state.push('text', '', 0);
    token.content = String.fromCharCode(marker);

    state.delimiters.push({
      // Char code of the starting marker (number).
      //
      marker,

      // Total length of these series of delimiters.
      //
      length: scanned.length,

      // A position of the token this delimiter corresponds to.
      //
      token: state.tokens.length - 1,

      // If this delimiter is matched as a valid opener, `end` will be
      // equal to its position, otherwise it's `-1`.
      //
      end: -1,

      // Boolean flags that determine if this delimiter could open or close
      // an emphasis.
      //
      open: scanned.can_open,
      close: scanned.can_close,
    });
  }

  state.pos += scanned.length;

  return true;
}

function postProcess(state, delimiters) {
  const max = delimiters.length;

  for (let i = max - 1; i >= 0; i -= 1) {
    const startDelim = delimiters[i];

    // if (!SYMBOLS.includes(startDelim.marker)) {
    //   continue;
    // }

    // // Process only opening markers
    // if (startDelim.end === -1) {
    //   continue;
    // }

    if (SYMBOLS.includes(startDelim.marker) && startDelim.end !== -1) {
      const endDelim = delimiters[startDelim.end];

      // If the previous delimiter has the same marker and is adjacent to this one,
      // merge those into one strong delimiter.
      //
      // `<em><em>whatever</em></em>` -> `<strong>whatever</strong>`
      //
      // const isStrong =
      //   i > 0 &&
      //   delimiters[i - 1].end === startDelim.end + 1 &&
      //   // check that first two markers match and adjacent
      //   delimiters[i - 1].marker === startDelim.marker &&
      //   delimiters[i - 1].token === startDelim.token - 1 &&
      //   // check that last two markers are adjacent (we can safely assume they match)
      //   delimiters[startDelim.end + 1].token === endDelim.token + 1;

      const ch = String.fromCharCode(startDelim.marker);

      const markerTag = SYMBOLS_TAGS[startDelim.marker];

      const token_o = state.tokens[startDelim.token];
      const token_c = state.tokens[endDelim.token];

      if (!token_o || !token_c) {
        return;
      }

      token_o.type = `${markerTag}_open`;
      token_o.tag = markerTag;
      token_o.nesting = 1;
      token_o.markup = ch;
      token_o.content = '';

      token_c.type = `${markerTag}_close`;
      token_c.tag = markerTag;
      token_c.nesting = -1;
      token_c.markup = ch;
      token_c.content = '';

      // if (isStrong) {
      //   state.tokens[delimiters[i - 1].token].content = '';
      //   state.tokens[delimiters[startDelim.end + 1].token].content = '';
      //   i--;
      // }
    }
  }
}

// Walk through delimiter list and replace text tokens with tags
//
function emphasis_post_process(state) {
  const tokens_meta = state.tokens_meta;
  const max = state.tokens_meta.length;

  postProcess(state, state.delimiters);

  for (let curr = 0; curr < max; curr += 1) {
    if (tokens_meta[curr] && tokens_meta[curr].delimiters) {
      postProcess(state, tokens_meta[curr].delimiters);
    }
  }
}

export default {
  tokenize: emphasis_tokenize,
  postProcess: emphasis_post_process,
};
